// Generated by Framer (838580a)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["XZGqvOROA"];

const serializationHash = "framer-XYXJf"

const variantClassNames = {XZGqvOROA: "framer-v-1uo9tx5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, title, width, ...props}) => { return {...props, ElcWiEzUi: title ?? props.ElcWiEzUi ?? "AVATA"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ElcWiEzUi, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "XZGqvOROA", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1uo9tx5", className, classNames)} data-border data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"XZGqvOROA"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "var(--token-055027d1-13b9-4efb-b926-a81a963c990b, rgba(255, 255, 255, 0.15))", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "var(--token-3ef2b024-43b5-4c0c-a7b2-ecacd057a93b, rgba(255, 255, 255, 0.05))", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW50ZXItNzAw", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "26px", "--framer-font-weight": "700", "--framer-letter-spacing": "-0.04em", "--framer-line-height": "1em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-c09d492a-e729-4cbb-b155-5952e8c0f0ee, rgb(250, 245, 234)))", "--framer-text-transform": "capitalize"}}>AVATA</motion.p></React.Fragment>} className={"framer-1g9saxr"} fonts={["GF;Inter-700"]} layoutDependency={layoutDependency} layoutId={"dfl_Ka2o5"} style={{"--extracted-r6o4lv": "var(--token-c09d492a-e729-4cbb-b155-5952e8c0f0ee, rgb(250, 245, 234))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={ElcWiEzUi} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XYXJf.framer-1lp9881, .framer-XYXJf .framer-1lp9881 { display: block; }", ".framer-XYXJf.framer-1uo9tx5 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 3px; height: 107px; justify-content: center; overflow: hidden; padding: 14px 48px 14px 48px; position: relative; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-XYXJf .framer-1g9saxr { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-XYXJf.framer-1uo9tx5 { gap: 0px; } .framer-XYXJf.framer-1uo9tx5 > * { margin: 0px; margin-bottom: calc(3px / 2); margin-top: calc(3px / 2); } .framer-XYXJf.framer-1uo9tx5 > :first-child { margin-top: 0px; } .framer-XYXJf.framer-1uo9tx5 > :last-child { margin-bottom: 0px; } }", ".framer-XYXJf[data-border=\"true\"]::after, .framer-XYXJf [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 107
 * @framerIntrinsicWidth 176
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","fixed"]}}}
 * @framerVariables {"ElcWiEzUi":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerKDdxa81Wf: React.ComponentType<Props> = withCSS(Component, css, "framer-XYXJf") as typeof Component;
export default FramerKDdxa81Wf;

FramerKDdxa81Wf.displayName = "Brands Copy";

FramerKDdxa81Wf.defaultProps = {height: 107, width: 176};

addPropertyControls(FramerKDdxa81Wf, {ElcWiEzUi: {defaultValue: "AVATA", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerKDdxa81Wf, [{explicitInter: true, fonts: [{family: "Inter", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/inter/v13/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZ1rib2Bg-4.woff2", weight: "700"}]}], {supportsExplicitInterCodegen: true})